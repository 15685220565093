<div class="inner-wraper lesson-pdd-sect" [ngClass]="isMenuIconsShow ? 'moveNavigationUp' : ''">
  <section class="lesson-cover-sect lesson-cover-sect-100 new_chgs02">
    <div class="container">
      <article *ngIf="loader">
        <div class="setting-arrow">
          <a href="javascript:;" (click)="navigate()">
            <i class="fas fa-chevron-left"></i>
            <h6>{{ global.termsArray["lbl_go_back"] }}</h6>
          </a>
        </div>
        <app-no-data-found *ngIf="lessons.length == 0"
          style="display: flex; justify-content: center; align-items: center"></app-no-data-found>
      </article>


      <!-- If data found -->
      <div class="lessons-slider" *ngIf="!loader">
        <div class="outs-box-fram" *ngIf="lessons.length > 0" [ngClass]="backgroundClass">

          <div class="slides-footer">
            <div class="down-navs">

              <!-- Labels for the nav bar -->
              <ul>
                <li>
                  <a href="javascript:void(0);"
                    [ngClass]="{'max_767_width': this.lessons[currentIndex].type == 'title', 'color-white-icon1': this.lessons[currentIndex].type != 'title', 'active newCss2': hearStatus == 1, 'newCss2': hearStatus != 1}"
                    (click)="hearStatus = 1; slidesFooter('active')" data-target="#video-carousel-example2"
                    attr.data-slide-to="{{ 0 }}">
                    <i class="ri-eye-line"></i>
                    <p>{{ global.termsArray["lbl_hearActively"] }}</p>
                  </a>

                  <div class="progress">
                    <span class="progress-bar" role="progressbar"
                      [style.width]="(saveHistory['active'].max / saveHistory.active['total']) * 100 + '%'"
                      aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                    </span>
                  </div>
                </li>

                <li>
                  <a href="javascript;:"
                    [ngClass]="{'max_767_width':this.lessons[currentIndex].type == 'title', 'color-white-icon1':this.lessons[currentIndex].type != 'title', 'active newCss2':hearStatus == 3, 'newCss2':hearStatus != 3}"
                    (click)="hearStatus = 3;  currentIndex = speakIndex - 1; slidesFooter('speak')"
                    data-target="#video-carousel-example2" attr.data-slide-to="{{ speakIndex-1 }}">
                    <i class="ri-chat-4-line"></i>
                    <p>{{global.termsArray["lbl_speak"] }}</p>
                  </a>

                  <div class="progress">
                    <span class="progress-bar" role="progressbar"
                      [style.width]="(saveHistory['speak'].max / saveHistory.speak['total']) * 100 + '%'"
                      aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></span>
                  </div>
                </li>

                <li>
                  <a href="javascript:void(0);"
                    [ngClass]="{'max_767_width':this.lessons[currentIndex].type == 'title', 'color-white-icon1':this.lessons[currentIndex].type != 'title', 'active newCss2':hearStatus == 4, 'newCss2':hearStatus != 4}"
                    (click)="hearStatus = 4; currentIndex = repeatIndex; slidesFooter('repeat')"
                    data-target="#video-carousel-example2" attr.data-slide-to="{{ repeatIndex }}">
                    <i class="fas fa-redo"></i>
                    <p>{{ global.termsArray["lbl_repetition"] }}</p>
                  </a>

                  <div class="progress">
                    <span class="progress-bar" role="progressbar"
                      [style.width]="(saveHistory['repeat'].max / saveHistory.repeat['total']) * 100 + '%'"
                      aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></span>
                  </div>
                </li>

                <li>
                  <a href="javascript:;"
                    [ngClass]="{'max_767_width':this.lessons[currentIndex].type == 'title', 'color-white-icon1':this.lessons[currentIndex].type != 'title', 'active newCss2':hearStatus == 2, 'newCss2':hearStatus != 2}"
                    (click)="hearStatus = 2; currentIndex = songLoopIndex-1; slidesFooter('passive');"
                    data-target="#video-carousel-example2"
                    attr.data-slide-to="{{ lessonType == 'sentences' ? songLoopIndex-1 : songLoopIndex-4 }}">
                    <i class="ri-headphone-line"></i>
                    <p>{{ global.termsArray["lbl_listen_passively"] }}</p>
                  </a>

                  <div class="progress">
                    <span class="progress-bar" role="progressbar" [style.width]="percent() + '%'" aria-valuenow="25"
                      aria-valuemin="0" aria-valuemax="100">
                    </span>
                    <!-- <span class="progress-bar" role="progressbar"
                      [style.width]="(saveHistory['passive'].max / saveHistory.passive['total']) * 100 + '%'"
                      aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></span> -->
                  </div>
                </li>
              </ul>
            </div>

          </div>

          <div id="video-carousel-example2" class="carousel slide carousel-fade"
            [ngClass]="isShowfakeBtn ? 'carousel-sec-fake' : ''" data-ride="carousel" data-interval="false"
            [hidden]="(!lessons && lessons.length == 0) || carosalHide">


            <div class="button-and-slider-sec">
              <div class="button-and-slider-sec-left">
                <!-- Lesson description breadcrumbs -->
                <div class="info-container-card">
                  <div class="cross-btn-sec"><button type="button" class="close-btn cp" aria-hidden="true"
                      (click)="goBack()">&times;</button></div>
                  <div class="info-container">
                    <div class="lesson-title">
                      {{ lessonTitle }}
                    </div>
                    <div class="bread-crumb">
                      {{ breadCrumb }}
                    </div>

                  </div>
                </div>
                <!-- Show play button if lesson type is mp3 -->
                <div class="view-icon fake-btns" *ngIf="isShowfakeBtn">
                  <span class="mr-3">
                    <a>
                      <circle-progress id="playButtonProgress" (click)="fakeCircleBtn()" [percent]="percent()"
                        [startFromZero]="false" [maxPercent]="100" [showZeroOuterStroke]="true" [radius]="30"
                        [space]="-2" [outerStrokeWidth]="0" [innerStrokeWidth]="0"
                        [outerStrokeColor]="percent() > 0 ? '#3399FF' : '#c3d6eb'" [innerStrokeColor]="'#c3d6eb'"
                        [animation]="true" [animationDuration]="300" [backgroundStrokeWidth]="0"
                        [outerStrokeLinecap]="'round'" [space]="-7" [showUnits]="false" [showTitle]="false"
                        [showSubtitle]="false" [showImage]="true" [showInnerStroke]="true" [showZeroOuterStroke]="true"
                        [imageSrc]="circleImage[fakeBtnData?.toggle]" [imageHeight]="25" [imageWidth]="25"
                        [responsive]="false" [class]="'circle-progress'" [backgroundColor]="'#fff'">
                      </circle-progress>
                    </a>
                  </span>
                  <!-- <div class="d-none">
                      <audio controls >
                        <source [src]="lesson[currentIndex]?.sound" type="audio/mp3">
                        Your browser does not support the audio element.
                      </audio>
                    </div> -->
                  <!-- <div id="speed_dropdown_mobile_flag" *ngIf="isMobileDevice" style="width: 94px;"></div> -->
                  <div class="dropdown" *ngIf="!isMobileDevice">
                    <button class="speed_dropdown" id="speedDrop" type="button" data-toggle="dropdown"
                      aria-expanded="false">
                      <span class="button_view">
                        <img height="10" width="10" src="assets/images/playIcon.svg" />
                      </span>
                      <span class="selected_text">{{defaultSpeed.toFixed(1)}}x</span>
                      <i class="ri-arrow-down-s-line"></i>
                    </button>
                    <ul class="dropdown-menu speed_options">
                      <li class="dropdown-item" [ngClass]="{active:option?.selected}"
                        *ngFor="let option of speedOptions;" (click)="selectOption(option)">
                        <i class="ri-check-fill"></i>
                        <span class="sl_value" [attr.data-value]="option?.value">{{option?.value.toFixed(1)}}x</span>
                      </li>
                    </ul>
                  </div>

                </div>


              </div>
              <div class="blue-screen-controls-sec">
                <div class="blue-screen-controls-inner">
                  <a class="carousel-control-prev" href="javascript:;" *ngIf="currentIndex > 0"
                    (click)="updateIndex(false);slideChanged();">
                    <span class="carousel-control-prev-icon" href="#video-carousel-example2" role="button"
                      data-slide="prev" aria-hidden="true"
                      [ngClass]="lessons[currentIndex].type == 'title' ? 'white-color' : ''"></span>
                    <span class="sr-only">Previous</span>
                  </a>

                  <a class="carousel-control-next" href="javascript:void(0);"
                    *ngIf="currentIndex < lessons.length - 1 || currentIndex == 0"
                    (click)="updateIndex(true);slideChanged();">
                    <span class="carousel-control-next-icon" href="#video-carousel-example2" role="button"
                      data-slide="next" aria-hidden="true"
                      [ngClass]="lessons[currentIndex].type == 'title' ? 'white-color' : ''"></span>
                    <span class="sr-only">Next</span>
                  </a>
                </div>


              </div>

              <div class="divider"></div>

            </div>

            <div class="carousel-inner lesson-sec-pdd" role="listbox">
              <div *ngFor="let lesson of lessons; let i = index"
                [ngClass]="(i == 0 ? 'carousel-item active ' + 'list_' + i : 'carousel-item ') + 'list_' + i">

                <div class="bg-carousel-box d-none">
                  <ol class="carousel-indicators scroo-dev-fix">
                    <li [ngClass]="j == 0 ? 'active' : ' ' + 'lists_' + j"
                      [ngStyle]="{'margin-left': j == 0 && this.lessons.length > 38 ? (this.lessons.length - 38) * 31 + 'px' : '0px'}"
                      id="scroll-dot-{{ j }}" *ngFor="let dropdown of lessons; let j = index"
                      data-target="#video-carousel-example2" attr.data-slide-to="{{ j }}"></li>
                  </ol>
                </div>

                <!-- If lesson type is title -->
                <div class="view finger-sec demo-lektion" *ngIf="lesson.type == 'title'">
                  <h5 [ngClass]="lesson.type == 'title' ? 'color-white' : '' "
                    *ngIf="lesson.heading && lessonType == 'sentences' && i == 0; else falsecondition"
                    style="font-family: Roboto;font-weight: 400;">
                    <p style="font-size:28px">{{ global.termsArray["lbl_welcome_to"] }} : {{ lesson.lessonID }} <br><br>
                    </p>
                    {{ global.termsArray[lesson.heading] }}
                  </h5>
                  <ng-template #falsecondition>
                    <h5 *ngIf="lesson.lessonID == 'lbl_demo' && lesson.heading != 'lbl_congratulations'"
                      [ngClass]="lesson.type == 'title' ? 'color-white' : '' ">
                      {{ global.termsArray[lesson.heading] }}
                    </h5>
                    <h5 *ngIf="lesson.lessonID != 'lbl_demo'" [ngClass]="lesson.type == 'title' ? 'color-white' : '' ">
                      {{ global.termsArray[lesson.heading] }}
                    </h5>
                  </ng-template>

                  <h5 *ngIf="lesson.state != 'passive'" [ngClass]="lesson.type == 'title' ? 'color-white' : ''">
                    {{ lessonType == "sentences" ? global.termsArray[lesson.message] : lesson.title }}
                  </h5>
                  <h5 *ngIf="lesson.state == 'passive' && lesson.lessonID == 'lbl_demo'"
                    [ngClass]="lesson.type == 'title' ? 'color-white' : ''">
                    {{ lessonType == "sentences" ? global.termsArray[lesson.message] : lesson.title }}
                  </h5>
                  <h5
                    *ngIf="lesson.state == 'passive' && lesson.lessonID != 'lbl_demo' && lesson.message != 'msg_passive'"
                    [ngClass]="lesson.type == 'title' ? 'color-white' : ''">
                    {{ lessonType == "sentences" ? global.termsArray[lesson.message] : lesson.title }}
                  </h5>

                  <div *ngIf="i==0" class="text-center finger-check">
                    <img src="assets/images/finger.png" />
                  </div>
                  <div *ngIf="lessons.length==(i+1)" class="text-center btn-blue" (click)="navigate('lesson')">
                    <a class="btn" href="javascript:;">{{ global.termsArray["lbl_to_lesson"] }}</a>
                  </div>
                  <span *ngIf="lesson?.button">
                    <a class="btn-flex-column">
                      <div class="bttn-navi-ph" (click)="navigate('lesson-description')">
                        <a class="btn" href="javascript:;">{{ global.termsArray["lbl_free_trail"] }}</a>
                      </div>
                    </a>
                  </span>

                </div>


                <!-- If lesson type is imagination -->
                <div class="view" *ngIf="lesson.type == 'imagination'">
                  <div class="listeng">
                    <a href="javascript:;"><img src="assets/images/Icon-top-situation.svg"
                        alt="icon top situation image" /></a>
                    <h3>{{ global.termsArray[lesson?.heading] }}</h3>
                  </div>
                  <div class="masks text-acaslon row">
                    <h2>{{ lesson?.sentence }}</h2>
                  </div>

                </div>

                <!-- Lesson type is compare -->
                <div class="view" *ngIf="lesson.type == 'compare'">
                  <div class="listeng">
                    <a href="javascript:;"><img src="assets/images/Icon-top-compare.svg"
                        alt="icon top comapre image" /></a>
                    <h3>{{ global.termsArray[lesson?.heading] }}</h3>
                  </div>
                  <div class="masks">
                    <div class="masks-inner-fxd">
                      <div class="row">
                        <div class="column"
                          style="background-color: rgb(255 255 255 / 21%); padding: 5px 2.5px 5px 2.5px; margin: 5px 2.5px 5px 2.5px; "
                          *ngFor="let sentence of lesson?.sentenceArray; let last = last; let first = first; let in = index ">
                          <h2
                            style="font-size: 22px; color: #fff; font-family: ACaslonPro-Bold; font-weight: 900; margin-top: 5px; ">
                            {{ sentence?.sentence1 }}
                          </h2>
                          <h2 *ngIf="sentence?.sentence3"
                            style="font-size: 18px; color: #fff; font-family: ACaslonPro-Bold; ">
                            {{ sentence?.sentence3 }}</h2>
                          <h2 style="font-family: ACaslonPro-Regular; font-size: 24px; margin-top: 20px; ">
                            {{ sentence?.sentence2 }}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Lesson type is repeat -->
                <div class="view" *ngIf="lesson.type == 'repeat'">
                  <div class="listeng">
                    <a href=""><img src="assets/images/Icon-top-repeat.svg" alt="icon top repeat image" /></a>
                    <h3>{{ global.termsArray[lesson?.heading] }}</h3>
                  </div>

                  <div class="masks text-acaslon">
                    <h2>{{ lesson?.sentence }}</h2>
                    <div class="row">
                      <div
                        style="background-color: rgb(255 255 255 / 21%); height: max-content; padding: 5px 2.5px 5px 2.5px;margin: 5px 2.5px 5px 2.5px;"
                        *ngFor="let item of splitMyWord(lesson?.sentence1)">
                        <h2 style="font-size: 26px; color: #fff; font-family: ACaslonPro-Bold; font-weight: 900;">{{
                          item }}</h2>
                      </div>
                    </div>
                  </div>
                </div>

                <ng-container *ngIf="lesson.type == 'song'">
                  <div class="view">
                    <div class="listeng">
                      <a href="javascript:;"><img src="assets/images/Icon-top-listen.svg"
                          alt="icon top listen image" /></a>
                      <h3>{{ global.termsArray[lesson?.heading] }}</h3>
                    </div>
                    <div class="masks">
                      <div class="masks-inner-fxd">
                        <div class="row">
                          <div class="column"
                            style="background-color: rgb(255 255 255 / 21%);/* background-color: none; */padding: 5px 2.5px 5px 2.5px;margin: 5px 2.5px 5px 2.5px;"
                            *ngFor="let sentence of lesson?.sentenceArray; let last = last; let first = first; let in = index">
                            <h2 style="font-size: 26px; color: #fff; font-family: ACaslonPro-Bold; font-weight: 900;">
                              {{ sentence?.sentence1 }}
                            </h2>
                            <h2 *ngIf="sentence?.sentence3"
                              style="font-size: 18px; color: #fff; font-family: ACaslonPro-Bold;">
                              {{ sentence?.sentence3 }}
                            </h2>
                            <h2 style="font-family: ACaslonPro-Regular; font-size: 22px; margin-top: 20px;color: #fff;">
                              {{ sentence?.sentence2 }}
                            </h2>
                          </div>
                        </div>
                        <div style="min-height: 100px; max-height: 100px; width: 100%; display: flex;"></div>
                      </div>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="lesson.type == 'songLoop'">
                  <div class="view" *ngIf="lesson.type == 'songLoop'">
                    <div class="lession-flex">
                      <div class="lession-flex-top">
                        <div class="listeng">
                          <h3 style="font-size: 20px">
                            {{ global.termsArray[lesson?.heading] }}
                          </h3>
                        </div>
                        <div class="time-section-less">
                          <div class="dropdown time-dropdown dropdown-height">
                            <button class="speed_dropdown" id="speedDrop" type="button" data-toggle="dropdown"
                              aria-expanded="false">
                              <svg width="53" height="58" viewBox="0 0 53 58" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <rect width="53" height="58" fill="white" />
                                  <path
                                    d="M37.268 2C36.5259 2 35.9288 2.60605 35.9288 3.35938C35.9288 4.1127 36.5259 4.71875 37.268 4.71875H40.5656L36.258 9.71445C35.9121 10.1166 35.8284 10.683 36.046 11.1701C36.2636 11.6572 36.7435 11.9688 37.268 11.9688H43.5173C44.2594 11.9688 44.8564 11.3627 44.8564 10.6094C44.8564 9.85605 44.2594 9.25 43.5173 9.25H40.2197L44.5272 4.2543C44.8732 3.85215 44.9569 3.28574 44.7393 2.79863C44.5217 2.31152 44.0418 2 43.5173 2H37.268ZM44.4101 14.6875C43.668 14.6875 43.0709 15.2936 43.0709 16.0469C43.0709 16.8002 43.668 17.4062 44.4101 17.4062H47.9197L43.3555 23.3705C43.043 23.7783 42.9872 24.3334 43.2104 24.8035C43.4336 25.2736 43.9023 25.5682 44.4156 25.5682H50.6594C51.4015 25.5682 51.9985 24.9621 51.9985 24.2088C51.9985 23.4555 51.4015 22.8494 50.6594 22.8494H47.1497L51.7195 16.8852C52.032 16.4773 52.0878 15.9223 51.8646 15.4521C51.6414 14.982 51.1727 14.6875 50.6594 14.6875H44.4101ZM28.3404 14.6875C27.5983 14.6875 27.0012 15.2936 27.0012 16.0469C27.0012 16.8002 27.5983 17.4062 28.3404 17.4062H35.5159L27.2579 28.8363C26.9622 29.2498 26.9175 29.7992 27.1407 30.2523C27.3639 30.7055 27.8382 31 28.3404 31H38.1607C38.9029 31 39.4999 30.3939 39.4999 29.6406C39.4999 28.8873 38.9029 28.2812 38.1607 28.2812H30.9852L39.2432 16.8512C39.5389 16.4377 39.5836 15.8883 39.3604 15.4352C39.1372 14.982 38.6629 14.6875 38.1607 14.6875H28.3404Z"
                                    fill="black" />
                                  <path
                                    d="M17.8377 15.9503C8.53365 19.7559 4.09189 30.3965 7.90512 39.7191C11.7184 49.0417 22.3439 53.5194 31.6479 49.7137C36.2133 47.8463 39.6033 44.3308 41.431 40.1346C41.6566 39.6113 41.5203 38.9984 41.0881 38.6298C40.6559 38.2611 40.0281 38.21 39.5453 38.513C38.8594 38.9431 38.1338 39.3191 37.3578 39.6365C30.0577 42.6225 21.7072 39.1058 18.7108 31.7803C16.6825 26.8213 17.6282 21.3936 20.7127 17.4751C21.0643 17.0234 21.0821 16.4003 20.7595 15.934C20.4369 15.4678 19.8427 15.2621 19.2968 15.4326C18.8068 15.5891 18.3229 15.7606 17.8408 15.9578L17.8377 15.9503Z"
                                    fill="black" />
                                </svg>
                              <span class="selected_text text-black" >{{  selectedSleepTimer==0 ? '--': selectedSleepTimer }}Min </span>
                              <!-- <span class="selected_text text-black"  *ngIf="!isSleepTimerSelected">Select</span> -->
                              <i class="ri-arrow-down-s-line"></i>
                              <span class="selected_text text-black" *ngIf="selectedSleepTimer!=0">({{overallTime}}) </span>
                            </button>
                            <ul class="dropdown-menu speed_options">
                              <li class="dropdown-item" [ngClass]="{active: option === selectedSleepTimer }"
                                *ngFor="let option of sleepTimerOptions" (click)="selectsleepTimerOption(option)">
                                <i class="ri-check-fill"></i>
                                <p class="sl_value" [attr.data-value]="option">{{ option }} Min</p>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="time-section-less time-section-less-bottom" style="margin-top: 3rem;">
                          <span>
                            <ng-container>
                              <h3  style="margin-bottom: 1.5rem;">
                                {{ timerValue }}
                              </h3>
                              <h6 class="ff-roboto">Empfohlen</h6>
                              <div class="progress-outer timer-progress-outer">
                                <span class="ff-roboto">0'</span>
                                <div class="progress timer-progress">
                                  <span class="progress-bar" id="timerProgress" role="progressbar" aria-valuenow="25" value="passiveListeningTime"
                                  aria-valuemin="0" aria-valuemax="100"></span>
                                </div>
                                <span class="ff-roboto">15'</span>

                                <!-- <span>{{selectedSleepTimer}}`</span> -->
                              </div>
                            <!-- </div> -->
                            </ng-container>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <!-- <ng-container *ngIf="lesson.type == 'songLoop'">
                  <div class="view" *ngIf="lesson.type == 'songLoop'">
                    <div class="listeng">
                      <h3 style="font-size: 20px">
                        {{ global.termsArray[lesson?.heading] }}
                      </h3>
                    </div>


                    <div class="time-section-less">
                      
                      <div class="dropdown time-dropdown">
                        <button class="speed_dropdown" id="speedDrop" type="button" data-toggle="dropdown"
                          aria-expanded="false">
                          <span class="timer_button_view">
                            <svg width="53" height="58" viewBox="0 0 53 58" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <rect width="53" height="58" fill="white" />
                              <path
                                d="M37.268 2C36.5259 2 35.9288 2.60605 35.9288 3.35938C35.9288 4.1127 36.5259 4.71875 37.268 4.71875H40.5656L36.258 9.71445C35.9121 10.1166 35.8284 10.683 36.046 11.1701C36.2636 11.6572 36.7435 11.9688 37.268 11.9688H43.5173C44.2594 11.9688 44.8564 11.3627 44.8564 10.6094C44.8564 9.85605 44.2594 9.25 43.5173 9.25H40.2197L44.5272 4.2543C44.8732 3.85215 44.9569 3.28574 44.7393 2.79863C44.5217 2.31152 44.0418 2 43.5173 2H37.268ZM44.4101 14.6875C43.668 14.6875 43.0709 15.2936 43.0709 16.0469C43.0709 16.8002 43.668 17.4062 44.4101 17.4062H47.9197L43.3555 23.3705C43.043 23.7783 42.9872 24.3334 43.2104 24.8035C43.4336 25.2736 43.9023 25.5682 44.4156 25.5682H50.6594C51.4015 25.5682 51.9985 24.9621 51.9985 24.2088C51.9985 23.4555 51.4015 22.8494 50.6594 22.8494H47.1497L51.7195 16.8852C52.032 16.4773 52.0878 15.9223 51.8646 15.4521C51.6414 14.982 51.1727 14.6875 50.6594 14.6875H44.4101ZM28.3404 14.6875C27.5983 14.6875 27.0012 15.2936 27.0012 16.0469C27.0012 16.8002 27.5983 17.4062 28.3404 17.4062H35.5159L27.2579 28.8363C26.9622 29.2498 26.9175 29.7992 27.1407 30.2523C27.3639 30.7055 27.8382 31 28.3404 31H38.1607C38.9029 31 39.4999 30.3939 39.4999 29.6406C39.4999 28.8873 38.9029 28.2812 38.1607 28.2812H30.9852L39.2432 16.8512C39.5389 16.4377 39.5836 15.8883 39.3604 15.4352C39.1372 14.982 38.6629 14.6875 38.1607 14.6875H28.3404Z"
                                fill="black" />
                              <path
                                d="M17.8377 15.9503C8.53365 19.7559 4.09189 30.3965 7.90512 39.7191C11.7184 49.0417 22.3439 53.5194 31.6479 49.7137C36.2133 47.8463 39.6033 44.3308 41.431 40.1346C41.6566 39.6113 41.5203 38.9984 41.0881 38.6298C40.6559 38.2611 40.0281 38.21 39.5453 38.513C38.8594 38.9431 38.1338 39.3191 37.3578 39.6365C30.0577 42.6225 21.7072 39.1058 18.7108 31.7803C16.6825 26.8213 17.6282 21.3936 20.7127 17.4751C21.0643 17.0234 21.0821 16.4003 20.7595 15.934C20.4369 15.4678 19.8427 15.2621 19.2968 15.4326C18.8068 15.5891 18.3229 15.7606 17.8408 15.9578L17.8377 15.9503Z"
                                fill="black" />
                            </svg>
                          </span>
                          <span class="selected_text">{{ selectedSleepTimer }} Min</span>
                          <i class="ri-arrow-down-s-line"></i>
                        </button>
                        <ul class="dropdown-menu speed_options">
                          <li class="dropdown-item" [ngClass]="{active: option === selectedSleepTimer }"
                            *ngFor="let option of sleepTimerOptions" (click)="selectsleepTimerOption(option)">
                            <i class="ri-check-fill"></i>
                            <p class="sl_value" [attr.data-value]="option">{{ option }} Min</p>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="time-section-less">
                      <span>
                        <ng-container [counter]="loopStopWatchTime" [interval]="900"
                          [status]="lesson?.toggle == 'play' ? true : false" (value)="value = $event">
                          <h3 style="color: rgb(195, 214, 235)">
                            {{ value?.value }}
                          </h3>
                          <span>0`</span>
                          <div class="progress">
                            <span class="progress-bar" role="progressbar" [style.width]="32+'%'" aria-valuenow="25"
                              aria-valuemin="0" aria-valuemax="100"></span>
                          </div>
                          <span>15`</span>
                       </div>//
                        </ng-container>
                      </span>
                    </div>
                  </div>
                </ng-container> -->

                <ng-container *ngIf="lesson.type == 'fast_song_with_sentence'">
                  <div class="view">
                    <div class="listeng">
                      <a href="javascript:;"><img src="assets/images/Icon-top-listen.svg"
                          alt="icon top listen image" /></a>
                      <h3>{{ global.termsArray[lesson?.heading] }}</h3>
                    </div>
                    <div class="masks newCss">
                      <div class="masks-inner-fxd">
                        <div class="row touch-event" *ngFor="let data of lesson.data; let j = index"
                          (click)="playSpeakTabAudios(j); $event.stopPropagation()" id="scroll-{{ i + '' + j }}"
                          [ngClass]="j == audioLoopIndex ? 'playing' : 'not-playing'"
                          style="border-bottom: solid 1px white; height: fit-content; margin-left: 0px; cursor: pointer;">
                          <div class="column"
                            style="background-color: none; padding: 5px 2.5px 5px 2.5px; margin: 5px 2.5px 5px 2.5px;"
                            *ngFor="let sentence of data?.sentenceArray;let last = last;let first = first;let in = index">
                            <h3 style="font-family: ACaslonPro-Bold; font-size: 20px; font-weight: 700;color: #fff;">
                              {{ sentence?.sentence1 }}</h3>
                            <h3 *ngIf="sentence?.sentence3"
                              style="font-family: ACaslonPro-Regular; font-size: 20px;color: #fff;">
                              {{ sentence?.sentence3 }}</h3>
                          </div>
                        </div>
                        <div style="height: 90px; min-height: 90px; max-height: 90px; width: 100%; display: flex;">
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="lesson.type == 'slow_song_with_sentence'">
                  <div class="view">
                    <div class="listeng">
                      <a href="javascript:;"><img src="assets/images/Icon-top-listen.svg"
                          alt="icon top listen image" /></a>
                      <h3>{{ global.termsArray[lesson?.heading] }}</h3>
                    </div>
                    <div class="masks newCss">
                      <div class="masks-inner-fxd">
                        <div class="row touch-event" *ngFor="let data of lesson.data; let j = index"
                          (click)="playSpeakTabAudios(j); $event.stopPropagation()" id="scroll-{{ i + '' + j }}"
                          [ngClass]="j == audioLoopIndex ? 'playing' : 'not-playing' "
                          style="width: fit-content; border-bottom: solid 1px white; height: fit-content; margin-left: 0px; cursor: pointer; ">
                          <div class="column"
                            style="background-color: none; padding: 5px 2.5px 5px 2.5px; margin: 5px 2.5px 5px 2.5px;"
                            *ngFor="let sentence of data?.sentenceArray; let last = last; let first = first; let in = index ">
                            <h3 style="font-family: ACaslonPro-Bold; font-size: 20px;">
                              {{ sentence?.sentence1 }}
                            </h3>
                            <h3 *ngIf="sentence?.sentence3" style="font-family: ACaslonPro-Regular; font-size: 20px;">
                              {{ sentence?.sentence3 }}
                            </h3>
                          </div>
                        </div>
                        <div style="height: 90px; min-height: 90px; max-height: 90px; width: 100%; display: flex;">
                        </div>
                      </div>
                    </div>
                  </div>

                </ng-container>

                <div class="list-sentens text-center" *ngIf="lesson.type == 'sentenceList'">
                  <div class="in-list-leson view">
                    <div class="listeng">
                      <a href="javascript:;" [hidden]="true"><img src="assets/images/Icon-top-listen.svg"
                          alt="icon top listen image" /></a>
                      <h3 class="in-list-leson">{{ global.termsArray["lbl_sentencelist"] }}</h3>

                    </div>
                    <div class="masks ov_auto">
                      <div class="point-sect" *ngFor="let sen of lesson?.data; let j = index;">
                        <a href="javascript:;" class="repeat-lesson d-flex">
                          <span [id]="sen?.index"  (click)="playRepeatTabAudio(sen?.index, j)">
                            <i
                              [ngClass]="repeatSelectedIndex == j ? 'fa fa-pause' : repeatPlayedIndex == j ? 'fa fa-refresh' : 'fas fa-play' "></i>
                          </span>

                          <div class="d-flex d-flex language-icons">
                            <img src="assets/images/translate-img.svg"  (click)="changedLessonId=i;selectedSentence=sen;"data-toggle="modal" data-target="#correctSentenceModal" />
                            <img src="assets/images/eye.svg"  data-toggle="modal" (click)="selectedSentence=sen;" data-target="#activeSentenceModal" />
                            <!-- (click)="goToActiveTab(sen?.index)" -->
                          </div>
                          <div class="list-sentens">
                            <div class="column"
                              style="background-color: rgb(255 255 255 / 21%);padding: 5px 2.5px 5px 2.5px;margin: 5px 2.5px 5px 2.5px;"
                              *ngFor="let item of splitMyWord(sen?.sentence)">
                              <h2 style="font-size: 26px; color: #fff; font-family: ACaslonPro-Bold; font-weight: 900;">
                                {{ item }}</h2>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>


                  </div>
                </div>

              </div>


            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
</div>
<div class="modal fade" id="myModal1" role="dialog" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog big-model-dialog-lesson">
    <div class="modal-content" style="background-color: white">
      <div class="modal-body" style="font-size: 20px; font-family: Akkurat-Bold; margin: 0; color: #4782c5; ">
        {{ this.global.termsArray["lbl_confirm_repeat_msg"] }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" style="font-size: 15px; font-family: Akkurat-Bold"
          data-dismiss="modal" data-toggle="modal" data-target="#myModal2">
          {{ global.termsArray["lbl_no"]}}
        </button>
        <button type="button" class="btn btn-primary" style="font-size: 15px; font-family: Akkurat-Bold"
          data-dismiss="modal" (click)="modalConfirmationFill()" data-target="#video-carousel-example2">
          {{ global.termsArray["lbl_yes"] }}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="myModal2" role="dialog" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog big-model-dialog-lesson">
    <div class="modal-content" style="background-color: white">
      <div class="modal-body" style="font-size: 20px; font-family: Akkurat-Bold; margin: 0; color: #4782c5; ">
        {{ this.global.termsArray["lbl_easily_repeat"] }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" style="font-size: 15px; font-family: Akkurat-Bold"
          data-target="#video-carousel-example2" attr.data-slide-to="{{ currentIndex -1}}" data-dismiss="modal">
          {{ global.termsArray["lbl_ok"]}}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade app-download-popup" id="myModal3" role="dialog" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog big-model-dialog-lesson">
    <div class="modal-content" style="background-color: white">
      <div class="modal-head">
        <span data-target="#myModal3" data-dismiss="modal" class="text-right"><i class="fas fa-times"></i></span>
      </div>
      <div class="modal-body" style="font-size: 20px; font-family: Akkurat-Bold; margin: 0; color: #4782c5; ">
        <h3 class="text-center">{{ global.termsArray["lbl_app_download_msg_1"] }}</h3>
        <h3 class="text-center">{{ global.termsArray["lbl_app_download_msg_2"] }}</h3>
      </div>
      <div class="modal-footer">
        <div class="button-gp-d space-margin">
          <a href="javascript:;" class="btn btn-ios d-flex" (click)="openUrl(1)">
            <figure>
              <img src="assets/images/google-play.svg" alt="google play image" />
            </figure>
            <figcaption>
              <span>{{ global.termsArray["lbl_home_get_it_on"] }}</span>
              <strong>
                {{ global.termsArray["lbl_home_google_play"] }}</strong>
            </figcaption>
          </a>
          <a href="javascript:;" class="btn btn-ios d-flex" (click)="openUrl(2)">
            <figure>
              <i class="fab fa-apple"></i>
            </figure>
            <figcaption>
              <span>
                {{ global.termsArray["lbl_home_download_on_the"] }}</span>
              <strong> {{ global.termsArray["lbl_home_app_store"] }}</strong>
            </figcaption>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade app-download-popup" id="myModal4" role="dialog" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog big-model-dialog-lesson">
    <div class="modal-content" style="background-color: white">
      <div class="modal-head">
        <span data-target="#myModal4" data-dismiss="modal" class="text-right" style="color: #8d5182 ; "><i class="fas fa-times"></i></span>
      </div>
      <div class="modal-body" style="font-size: 20px; font-family: Akkurat-Bold; margin: 0; color: #8d5182 ; ">
        <h3 class="text-center" style="color: #8d5182 ; ">{{ global.termsArray["safari_mobile_text_1"] }}</h3>
        <h3 class="text-center" style="color: #8d5182 ; ">{{ global.termsArray["safari_mobile_text_2"] }}</h3>
      </div>
      <div class="modal-footer">
        <div class="button-gp-d space-margin">
          <a href="javascript:;" class="btn btn-ios d-flex" (click)="openUrl(1)">
            <figure>
              <img src="assets/images/google-play.svg" alt="google play image" />
            </figure>
            <figcaption>
              <span>{{ global.termsArray["lbl_home_get_it_on"] }}</span>
              <strong>
                {{ global.termsArray["lbl_home_google_play"] }}</strong>
            </figcaption>
          </a>
          <a href="javascript:;" class="btn btn-ios d-flex" (click)="openUrl(2)">
            <figure>
              <i class="fab fa-apple"></i>
            </figure>
            <figcaption>
              <span>
                {{ global.termsArray["lbl_home_download_on_the"] }}</span>
              <strong> {{ global.termsArray["lbl_home_app_store"] }}</strong>
            </figcaption>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade app-download-popup" id="myModal5" role="dialog" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog big-model-dialog-lesson">
    <div class="modal-content" style="background-color: white">
      <div class="modal-head">
        <span data-target="#myModal5" data-dismiss="modal" class="text-right"><i class="fas fa-times"></i></span>
      </div>
      <div class="modal-body" style="font-size: 20px; font-family: Akkurat-Bold; margin: 0; color: #4782c5; ">
        <h3 class="text-center">{{ global.termsArray["safari_desktop_text_1"] }}</h3>
        <h3 class="text-center">{{ global.termsArray["safari_desktop_text_2"] }}</h3>
      </div>
      <div class="modal-footer">
        <div class="button-gp-d space-margin">
          <a href="javascript:;" class="btn btn-ios d-flex" (click)="openUrl(1)">
            <figure>
              <img src="assets/images/google-play.svg" alt="google play image" />
            </figure>
            <figcaption>
              <span>{{ global.termsArray["lbl_home_get_it_on"] }}</span>
              <strong>
                {{ global.termsArray["lbl_home_google_play"] }}</strong>
            </figcaption>
          </a>
          <a href="javascript:;" class="btn btn-ios d-flex" (click)="openUrl(2)">
            <figure>
              <i class="fab fa-apple"></i>
            </figure>
            <figcaption>
              <span>
                {{ global.termsArray["lbl_home_download_on_the"] }}</span>
              <strong> {{ global.termsArray["lbl_home_app_store"] }}</strong>
            </figcaption>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>



<button type="button" class="d-none" id="mobile-app-dwn-popup" data-dismiss="modal" data-toggle="modal"
  data-target="#myModal3"></button>

<button type="button" class="d-none" id="safari-mobile-app-dwn-popup" data-dismiss="modal" data-toggle="modal"
  data-target="#myModal4"></button>

<button type="button" class="d-none" id="safari-desktop-app-dwn-popup" data-dismiss="modal" data-toggle="modal"
  data-target="#myModal5"></button>


<div id="speed_container_mobile" *ngIf="isMobileDevice && isShowfakeBtn">
  <div class="dropdown">
    <button class="speed_dropdown" id="speedDrop" type="button" data-toggle="dropdown" aria-expanded="false">
      <span class="button_view">
        <img height="10" width="10" src="assets/images/playIcon.svg" />
      </span>
      <span class="selected_text">{{defaultSpeed.toFixed(1)}}x</span>
      <i class="ri-arrow-down-s-line"></i>
    </button>
    <ul class="dropdown-menu speed_options">
      <li class="dropdown-item" [ngClass]="{active:option?.selected}" *ngFor="let option of speedOptions;"
        (click)="selectOption(option)">
        <i class="ri-check-fill"></i>
        <span class="sl_value" [attr.data-value]="option?.value">{{option?.value.toFixed(1)}}x</span>
      </li>
    </ul>
  </div>
</div>


<div class="alarm_container closePopup" *ngIf="showTimerPopup" (click)="setTimerPopup(false,$event);">
  <div class="alarm_content anim_popup">
    <h3>Set timer</h3>
    <p class="w-100">Your lesson history will automaticly saved after {{alarmTime}} minutes and the audio will stop.</p>
    <div class="d-flex mt-3">
      <button type="button" class="alarm_btns alarm_minus" (click)="adjustAlarmTime();">
        <i class="ri-subtract-line"></i>
      </button>
      <input type="number" class="form-control mx-1" min="1" max="10" [(ngModel)]="alarmTime"
        (input)="checkAlarmTime($event);">
      <button type="button" class="alarm_btns alarm_plus" (click)="adjustAlarmTime('+')">
        <i class="ri-add-line"></i>
      </button>
    </div>
    <div class="d-flex mt-5">
      <button class="btn btn-outline-danger mr-3" (click)="setTimerPopup(false);resetAlarm();">Cancel</button>
      <button class="btn btn-outline-success" (click)="setTimerPopup(false);setAlarm();">Set</button>
    </div>
  </div>
</div>




<div class="modal modal-radius fade" id="correctSentenceModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered " role="document">
    <div class="modal-content">
      <div class="modal-body" style="padding: 0 !important;">
        <div class="modal-heading text-center">
          <!-- <h4>{{ global.termsArray["lbl_correct_sentence"] ? global.termsArray["lbl_correct_sentence"] : "Correct Sentence" }}</h4> -->
          <div class="text-center my-4">
            <p>{{ selectedSentence?.sentenceInLearning ? removeSpecialCharacters(selectedSentence?.sentenceInLearning)  : global.termsArray['lbl_no_sentence']}}</p>
            <button type="button" class="close close-btn-border" data-dismiss="modal" aria-label="Close" (click)="selectedSentence=null;">
              <i class="ti ti-x"></i> {{ global.termsArray["lbl_close_modal"] }}
            </button>
          </div> 
        </div>
       
      </div>
        
    </div>
  </div>
</div>



<div class="modal modal-radius fade" id="activeSentenceModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered " role="document">
    <div class="modal-content goals">
      <div class="modal-body">
        <div class="modal-heading text-center">
          <p>{{ global.termsArray["active_sentence_heading"] }}</p>
        </div>
        <div class="btn-learning-modal my-2">
          <button class="btn btn-sm mx-2" (click)="goToActiveTab(selectedSentence?.index)" data-dismiss="modal" aria-label="Close"><img src="assets/images/eye.svg" class="active-eye"/>{{ global.termsArray["active_sentence_btn1"] }}</button>
          <button class="btn outline-btn btn-sm mx-2" (click)="selectedSentence=null;"  data-dismiss="modal" aria-label="Close"> <i class="ti ti-x" style=" margin-left: -10px;"></i> {{ global.termsArray["active_sentence_btn2"] }} </button>
        </div>
      </div>
        
    </div>
  </div>
</div>