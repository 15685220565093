
import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  HostListener,
  AfterViewInit,
  ElementRef,
} from "@angular/core";
import { of } from "rxjs";
import { GlobalService } from "src/app/global.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { type } from "jquery";
import { DeviceDetectorService } from "ngx-device-detector";
import is from "is_js";
import { stringify } from "querystring";
import * as e from "express";
import { Howl } from 'howler';


export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37
}

declare var $: any;

@Component({
  selector: 'app-play-fav-lessons',
  templateUrl: './play-fav-lessons.component.html',
  styleUrls: ['./play-fav-lessons.component.scss']
})
export class PlayFavLessonsComponent implements OnInit {

  speedOptions = [
    { value: 0.5, selected: false },
    { value: 0.6, selected: false },
    { value: 0.7, selected: false },
    { value: 0.8, selected: false },
    { value: 0.9, selected: false },
    { value: 1.0, selected: false },
  ];
  value: any;
  isSubscribed: any;
  lessonTitle: string;
  breadCrumb: string;
  circleImage = {
    pause: "assets/images/playIcon.svg",
    play: "assets/images/pauseIcon.svg",
    refresh: "assets/images/refreshIcon.svg",
  };

  defaultSpeed: number = 0.8;
  isPlaying: boolean = false;
  isMobileDevice = false;
  public currentFile: any = {};
  public currentIndex = 0;
  public lessons: Array<any> = [];
  public demoFamily: any;
  public isMenuIconsShow: boolean = false;
  public isShowfakeBtn: boolean = true;
  public fakeBtnData: any;
  public backgroundClass: any = "";
  browserName = "";
  isSafari: Boolean = false;
  isSafariMobile: Boolean = false;
  private sound: Howl;
  playingPassiveSentence: any = 0
  loopStopWatchTime: any = 0;
  alarmRealSeconds: number = 0;
  selectedSleepTimer: number = 0; // Default selected speed
  sleepTimerOptions: number[] = this.generateSpeedOptions(); // Generate speed options
  alarmTimer: any;
  sleepTimerValue: any;
  overallTime: any = '00:00:00';
  elapsedTime:any = 0;
  constructor(
    public global: GlobalService,
    private route: ActivatedRoute,
    public router: Router,
    private ngxService: NgxUiLoaderService,
    public breakpointObserver: BreakpointObserver,
    public location: Location,
    private deviceService: DeviceDetectorService
  ) {
    this.global.loader = true;
    this.global.profileTab = 1;

    this.getSubsriptionStatus();
    this.browserName = this.detectBrowserName();
    if (this.browserName == "safari") {
      this.isSafari = true
    } else {
      this.isSafari = false
    }

  }

  ngOnInit() {
    this.backgroundClass = "outs-box-cover4";
    this.lessonTitle = sessionStorage.getItem('lessonTitle') || "";
    this.breadCrumb = (sessionStorage.getItem('familyTitle') || "") + " > " + (sessionStorage.getItem('lessonLevel') || "");
    this.getLessons()
    this.initSpeedView();
    this.speedOptions.forEach((item) => {
      if (item.value == this.defaultSpeed) {
        item.selected = true;
      }
    });
    this.isMobileDevice = this.deviceService.isMobile();

    sessionStorage.setItem(btoa("favAlarmFlag"), btoa("true"));
    sessionStorage.setItem(btoa("alarmCurrentTimeFav"), btoa(String(this.selectedSleepTimer * 60)));
  }

  detectBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  getLessons() {
    let body = {
      learning_language_id: this.global.user.learning_language_id
    };
    this.global.post("fav-lesson-sentences", JSON.stringify(body), (data) => {
      if (data.success) {
        this.lessons = data.data;
      } else {
        this.global.showDangerToast(this.global.termsArray[data.message]);
        // this.loader = true;
      }
    }, (err) => {
      this.global.showDangerToast(err.message);
    }, true);

  }

  getSubsriptionStatus() {
    this.global.get(
      "getSubsriptionStatus",
      (data) => {
        if (data.success) {
          this.isSubscribed = data.data.is_subsribed;
        }
      },
      (err) => { },
      true
    );
  }

  navigate(slug = null) {
    this.global.profileTab = 1;
    if (slug == "lesson-description") {
      this.router.navigate([slug + "/" + this.demoFamily], {
        replaceUrl: true,
      });
    } else if (slug) {
      this.router.navigate([slug], { replaceUrl: true });
    } else {
      // this.router.navigate(["choose-lesson"], { replaceUrl: true });
      history.back();
    }
  }

  setLimit(event: any) {
    this.isPlaying = false
    this.sound.stop();
    this.sound = null
    if (this.lessons[this.currentIndex]["toggle"]) {
      this.lessons[this.currentIndex]["toggle"] = "pause";
    }
    this.currentFile = {};
    let speed = event;
    localStorage.setItem("speed", speed.toString());
    this.initSpeedView();
  }

  showMenuIcons() {
    this.isMenuIconsShow = !this.isMenuIconsShow;
  }

  async fakeCircleBtn() {
    this.isPlaying = !this.isPlaying
    let alarmCurrentTime = atob(sessionStorage.getItem(btoa("alarmCurrentTimeFav"))) as any;
    alarmCurrentTime = isNaN(alarmCurrentTime) ? 0 : Number(alarmCurrentTime);
    this.alarmRealSeconds = alarmCurrentTime;
  
    if (this.isPlaying) {
      if (this.alarmRealSeconds > 0) {
        this.initAlarm();
      }
      else {
        // this.resetProgressBar()
        setTimeout(() => {
          sessionStorage.setItem(btoa("alarmFlagFav"), btoa("true"));
          sessionStorage.setItem(btoa("alarmCurrentTimeFav"), btoa(String(this.selectedSleepTimer * 60)));
          this.initAlarm();
        }, 500);
      }
    }
    else {
      this.pausePassiveTabAudio();
      clearInterval(this.alarmTimer);
    }
  }


  percent() {
    return this.value && this.value.count ? (this.value.count / 900) * 100 : 0;
  }

  goBack() {
    this.location.back();
  }

  initSpeedView() {
    if (
      localStorage.getItem("speed") &&
      Number(localStorage.getItem("speed")) > 0
    ) {
      this.defaultSpeed = Number(localStorage.getItem("speed"));
    } else {
      this.defaultSpeed = 0.8;
    }
  }


  selectOption(event: any) {
    this.pausePassiveTabAudio()
    this.speedOptions.forEach((item) => {
      item.selected = false;
    });
    event.selected = true;
    this.setLimit(event.value);
  }


  initAlarm() {
    let alarmCurrentTime = atob(sessionStorage.getItem(btoa("alarmCurrentTimeFav"))) as any;
    alarmCurrentTime = isNaN(alarmCurrentTime) ? 0 : Number(alarmCurrentTime);
    this.alarmRealSeconds = alarmCurrentTime;

    if ((this.alarmRealSeconds > 0 || this.selectedSleepTimer===0) && this.isPlaying) {
      try {
        this.playPassiveTabAudio();
      } catch (err) {
        console.log("Dom exception", err);
      }
    }
    if (this.alarmTimer && this.selectedSleepTimer !==0) {
      clearInterval(this.alarmTimer);
    }
    this.alarmTimer = setInterval(() => {
      if (this.alarmRealSeconds > 0 || this.selectedSleepTimer ===0 ) {
        this.updateCountdown(this.elapsedTime)
        this.elapsedTime += 1;
        this.alarmRealSeconds = this.selectedSleepTimer === 0 ? 0 :  this.alarmRealSeconds-1;
        sessionStorage.setItem(btoa("alarmCurrentTimeFav"), btoa(String(this.alarmRealSeconds)));
        this.updateProgressBar();
      } else {
        this.playingPassiveSentence = 0
        this.pausePassiveTabAudio();
        sessionStorage.setItem(btoa("favAlarmFlag"), btoa("true"));
        sessionStorage.setItem(btoa("alarmCurrentTimeFav"), btoa(String(this.selectedSleepTimer * 60)));
        clearInterval(this.alarmTimer);
        this.elapsedTime = 0
        this.isPlaying=false
      }
    }, 1000);
  }


  playPassiveTabAudio() {
    this.fakeBtnData = this.lessons;
    let sentences = this.lessons ? this.lessons.map((item) => item.sentences) : [];
    sentences = sentences.reduce((acc, curr) => acc.concat(curr), []);
    let sounds = sentences.map((item) => item.audioUrl);
    let speed = localStorage.getItem("speed") ? Number(localStorage.getItem("speed")) : this.defaultSpeed;
    const soundDurationOffset = 0.5;
    const playNextSound = () => {
      if (this.alarmRealSeconds <= 0 && this.selectedSleepTimer!==0) {
        this.pausePassiveTabAudio();
        return;
      }

      this.sound = new Howl({
        src: [sounds[this.playingPassiveSentence]],
        html5: true,
        autoplay: false,
        loop: false,
        volume: 1,
        rate: speed,
      });

      this.sound.once('load', () => {
        if (this.sound && this.isPlaying) {
          this.fakeBtnData['toggle'] = "play";
          this.sound.play();
        } else {
          if(this.selectedSleepTimer===0){
            clearInterval(this.alarmTimer);
          }
          this.pausePassiveTabAudio();
        }
      });

      this.sound.on('play', () => {
        this.fakeBtnData['toggle'] = "play";
      });

      this.sound.on('end', () => {
        // Move to the next sound or restart if all sounds are played
        if (this.playingPassiveSentence < sounds.length - 1) {
          this.playingPassiveSentence++;
        } else {
          this.playingPassiveSentence = 0; 
          if(this.selectedSleepTimer==0){
            this.pausePassiveTabAudio();
          }
        }

        // Check if the remaining time is sufficient for the next sound
        if (this.alarmRealSeconds > soundDurationOffset && this.selectedSleepTimer!==0) {
          this.sound.unload();
          playNextSound(); 
        } 
        else if(this.selectedSleepTimer==0 && this.playingPassiveSentence <= sounds.length -1 ){
          this.sound.unload();
          playNextSound();
        }
        else {
          this.pausePassiveTabAudio(); 
        }
      });
    };

    if (this.isPlaying) {
      playNextSound();
    } else {
      this.isPlaying = false;
      this.pausePassiveTabAudio();
    }
  }


  pausePassiveTabAudio() {
    this.isPlaying = false;
    if (this.sound) {
      this.sound.unload()
    }
    if (this.fakeBtnData) {
      this.fakeBtnData.toggle = "pause";
    }
    // this.resetProgressBar();
  }


  ngOnDestroy() {
    this.pausePassiveTabAudio();
    this.resetProgressBar();
  }


  // Function to generate speed options from 5 to 60 minutes with an interval of 5
  generateSpeedOptions(): number[] {
    const options = [0];
    for (let i = 5; i <= 60; i += 5) {
      options.push(i);
    }
    return options;
  }

  // Function to select a speed option
  selectsleepTimerOption(option: number) {
    this.resetProgressBar()
    this.selectedSleepTimer = option;
    sessionStorage.setItem(btoa("favAlarmFlag"), btoa("true"));
    sessionStorage.setItem(btoa("alarmCurrentTimeFav"), btoa(String(option * 60)));
    this.sleepTimerValue = null
  }


  getProgressWidth() {
    let alarmCurrentTimeFav = atob(sessionStorage.getItem(btoa("alarmCurrentTimeFav"))) as any;
    alarmCurrentTimeFav = isNaN(alarmCurrentTimeFav) ? 0 : Number(alarmCurrentTimeFav);
    this.alarmRealSeconds = alarmCurrentTimeFav;
    const time = Math.floor(this.alarmRealSeconds / 60);
    const maxTime = this.selectedSleepTimer; // Max time in minutes
    const progressPercentage = (maxTime - time / maxTime) * 100;
    console.log(time, maxTime, progressPercentage)
    return `${progressPercentage}%`;
  }

  updateProgressBar() {
    const maxTime = this.selectedSleepTimer * 60;
    const progressPercentage = ((maxTime - this.alarmRealSeconds) / maxTime) * 100;
    if (document.querySelector('#timerProgress')) {
      document.querySelector('#timerProgress').setAttribute('style', `width: ${progressPercentage}%`);
    }

  }

  resetProgressBar() {
    this.isPlaying = false
    this.pausePassiveTabAudio();
    sessionStorage.removeItem(btoa("favAlarmFlag"));
    sessionStorage.removeItem(btoa("alarmCurrentTimeFav"));
    clearInterval(this.alarmTimer);
    if (document.querySelector('#timerProgress')) {
      document.querySelector('#timerProgress').setAttribute('style', `width:0%`);
    }
  }

  updateCountdown(time) {
    time++;
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    this.overallTime = `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes
      }:${seconds < 10 ? `0${seconds}` : seconds}`;

  }

}
