import { Location } from "@angular/common";
import { Component, OnInit, ViewChild, TemplateRef, HostListener, ElementRef } from "@angular/core";
import { GlobalService } from "src/app/global.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import * as moment from "moment";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { EnvService } from 'src/app/env.service';
import { FormBuilder, Validators } from "@angular/forms";

declare var $: any;
declare var dataLayer: any;
declare var paypal: any;
declare var PostAffTracker: any;
@Component({
  selector: "app-account",
  templateUrl: "./account.component.html",
  styleUrls: ["./account.component.scss"],
})

export class AccountComponent implements OnInit {

  couponCode;
  couponCodeId;
  couponCodeData;
  acceptTerms: any = false;
  show: any = false
  planAlreadyPurchased: any = false;
  selectedPlanID: any;
  is_unlimited_subscribed: any = false;
  is_unlimited: any = false;
  is_coupon_Applied: any = false;
  subscribedId: any = null;
  selectPlanData: any;
  subscriptionList: any;
  modalRef: BsModalRef;
  cardNumber: string;
  expiryMonth: string;
  expiryYear: string;
  cvc: string;
  message: string;
  isSubscribed: any;
  subscribedFromApp: Boolean = false;
  SUBSCRIBED_FROM_WEB = "website";
  enableSofort: boolean = false;
  paid_by: string = "";
  public isUnlimitedPlan: boolean = false;
  public subContent: any;
  public product_id: any;
  public payment_intent_client_secret: any = '';
  public stripe_public_key: any = '';
  public stripe: any = '';
  public isDirectPurchase: boolean = false;
  public subData: any;
  paymentOptionsForm:any;
  userCountry:string = "";
  paymentMethod:string = "";
  activePaymentOptions:any = [];
  // allowedEmailsForFakeButton:any = [];
  allowedEmailsForFakeButton:any = [];
  @ViewChild('couponcode',{static: false}) couponcodeModelRef:TemplateRef<HTMLElement>;
  @ViewChild('giftcode',{static: false}) giftCodeModelRef:TemplateRef<HTMLElement>;
  @ViewChild('giftCodeDiscountResult',{static: false}) giftCodeDiscountResultModelRef:TemplateRef<HTMLElement>;
  @ViewChild('chooseGiftCardSubscriptionPaymentType',{static: false}) chooseGiftCardSubscriptionPaymentTypeModelRef:TemplateRef<HTMLElement>;
  
  giftCode;
  giftCodeId;
  giftCodeData;

  giftCardPaymentOptionsForm:any;
  giftCardpaymentMethod: any;
  deprecatedSubscriptionList:any;
  // @ViewChild('couponcodeForStripe',{static: false}) couponcodeStripeModelRef:TemplateRef<HTMLElement>;
  constructor(
    private location: Location,
    public global: GlobalService,
    private ngxService: NgxUiLoaderService,
    private modalService: BsModalService,
    public router: Router,
    public envservice: EnvService,
    // private gtmService: GoogleTagManagerService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder
  ) {
    this.global.profileTab = 2;
    this.getSubsriptionStatus();
    this.getSubscriptionData();
    this.global.getSettingData()
    this.envservice.application()
    this.acceptTerms = false;
    this.product_id = this.global.routeParams && this.global.routeParams['product_id'] ? this.global.routeParams['product_id'] : "";
    
    /** //to manage sofort payment
      this.activatedRoute.paramMap.subscribe((params) => {
      this.payment_intent_client_secret = this.activatedRoute.snapshot.queryParams['payment_intent_client_secret'] ? this.activatedRoute.snapshot.queryParams['payment_intent_client_secret'] : "";
      if (this.payment_intent_client_secret != "") {
        setTimeout(() => {
          this.updatePaymentStatus(this.payment_intent_client_secret); return;
        }, 2000);
      }
    });
    */
    if (this.product_id != "") {
      this.isDirectPurchase = true;
    } else {
      this.isDirectPurchase = false;
    }
  }

  ngOnInit() {
    this.global.get("getSettingData", (response:any) => {
      this.activePaymentOptions = response && response.data && response.data.payment_options;
      this.allowedEmailsForFakeButton = response && response.data && response.data.allowed_emails_for_gtm_test ? response.data.allowed_emails_for_gtm_test.split(','):[];
    }, (err) => { });
    this.getSubscriptionContent();
    this.userCountry = this.global && this.global.user && this.global.user.country && this.global.user.country.name ? this.global.user.country.name : "";
    this.enableSofort = this.userCountry == "Austria" || this.userCountry == "Germany" ? true : false;
    this.paymentOptionsForm = this.fb.group({
      payment_method: ['',Validators.required]
    });
  }



  /** Get users subscription info */
  getSubscriptionData() {
    let body = {};
    body["language_id"] = this.global.selectLanguage;
    body["user_id"] = this.global.user._id;
    // body['showAll'] = 'true';
    this.global.post("subscriptions", JSON.stringify(body), (data) => {
      if (data.success) {
        this.subscriptionList = data.data;
        // const subscriptions = data.data;
        // this.subscriptionList = subscriptions.filter((subs)=>subs.base_sub_status==1);
        // this.deprecatedSubscriptionList = subscriptions.filter((subs)=>subs.base_sub_status==0);
        if (this.isDirectPurchase) {
          this.purchaseInitially();
        }
        const subLength = this.subscriptionList.length;
        for (let i = 0; i < subLength; i++) {
          if (this.subscriptionList[i].status == "1") {
            if (this.subscriptionList[i] && this.subscriptionList[i].paid_by) {
              this.paid_by = this.subscriptionList[i].paid_by;
            } else {
              this.paid_by = "";
            }
            break;
          } else {
            this.subscribedFromApp = false;
          }
        };
        for (let i = 0; i < subLength; i++) {
          if (this.subscriptionList[i].subs_source && this.subscriptionList[i].subs_source != this.SUBSCRIBED_FROM_WEB) {
            this.subscribedFromApp = true;
            break;
          } else {
            this.subscribedFromApp = false;
          }
        };
        for (let i = 0; i < subLength; i++) {
          if (this.subscriptionList[i].validity == 240 && this.subscriptionList[i].status == "1") {
            this.is_unlimited_subscribed = true;
            break;
          } else {
            this.is_unlimited_subscribed = false;
          }
        };
      } else {
        this.global.showDangerToast("Error", this.global.languageArray[data.message]);
      }
    }, (err) => {
      this.global.showDangerToast("Error", err.message);
    }, true);
  }

  getSubsriptionStatus() {
    this.global.get("getSubsriptionStatus", (data) => {
      if (data.success) {
        this.isSubscribed = data.data.is_subsribed;
      }
    }, (err) => { }, true);
  }


  getSubscriptionContent() {
    this.global.get("subscription-content", data => {
      this.subContent = data.data ? data.data : {};
    }, error => { }, true);
  }
  /** Get users subscription info ends */

  /** To manage sofort payment 
   * disbaled for now
   
  // updatePaymentStatus(clientSecret) {
  //   let _this = this;
  //   let stripe_public_key = _this.envservice.settings.payment_mode == 1 ? _this.envservice.settings.public_key_live : _this.envservice.settings.public_key_test;
  //   let stripe = (<any>window).Stripe(stripe_public_key);
  //   let postData = {};
  //   stripe.retrievePaymentIntent(clientSecret).then(function (response) {
  //     if (response.error) {
  //     } else if (response.paymentIntent && response.paymentIntent.status === 'succeeded') {
  //       let postData = {
  //         intent_clientSecret: clientSecret,
  //         status: 'succeeded'
  //       }
  //       _this.postPaymentStatus(postData);
  //     }
  //     else if (response.paymentIntent && response.paymentIntent.status === 'processing') {
  //       let postData = {
  //         intent_clientSecret: clientSecret,
  //         status: 'processing'
  //       }
  //       _this.postPaymentStatus(postData);
  //     }
  //     else if (response.paymentIntent && response.paymentIntent.status === 'payment_failed') {
  //       let postData = {
  //         intent_clientSecret: clientSecret,
  //         status: 'failed'
  //       }
  //       _this.postPaymentStatus(postData);
  //     }
  //   })
  // }

  // postPaymentStatus(postData) {
  //   this.global.post("updateSofortPaymentStatus", postData, (data) => {
  //     window.location.href = '/account';
  //   }, (err) => {
  //     this.global.showDangerToast("Error", err.message);
  //   }, true);
  // }

  */
  /** //previous configurration 
  openCheckout(couponApplied = false, couponCodeData = {}) {
    var price;
    if (couponApplied && couponCodeData["discount_less"]) {
      price = couponCodeData["discount_less"];
    } else if (this.selectPlanData.name == "Unbegrenzt") {
      price = this.selectPlanData.price;
    } else if (this.selectPlanData.name == "One Day Test") {
      price = this.selectPlanData.price;
    } else {
      price = this.selectPlanData.price * this.selectPlanData.validity;
    }

    let _this = this;
    this.global.loader = true;
    this.ngxService.start();
    var handler = (<any>window).StripeCheckout.configure({
      key: this.envservice.settings.payment_mode == 1 ? this.envservice.settings.public_key_live : this.envservice.settings.public_key_test,
      locale: "auto",
      currency: "eur",
      closed: () => {
        setTimeout(() => {
          this.global.loader = false;
          this.ngxService.stop();
        }, 3000);
      },
      panelLabel: this.global.termsArray["lbl_subscription_pay"] ? this.global.termsArray["lbl_subscription_pay"] : "Pay",
      token: (token: any) => {
        if (this.isUnlimitedPlan) {
          this.chargeForUnlimitedSubscription(token.id, price, couponApplied, couponCodeData);
        } else {
          this.purchaseSubscription(token.id, couponApplied, couponCodeData);
        }
      },
    });

    handler.open({
      image: "https://stripe.com/img/documentation/checkout/marketplace.png",
      name: "Birlingo",
      amount: 100 * price,
      allowRememberMe: false,
      description: `${this.global.termsArray["lbl_subscription_amount"]
        } € ${price.toFixed(2)}`,
      email: this.global.user.email,
      closed: function () {
        _this.global.loader = false;
        _this.ngxService.stop();
      }
    })
  }


  chargeForUnlimitedSubscription(token, price, couponApplied, couponCodeData) {
    let classInstance = this;
    price = price.toFixed(2);
    let body = {};
    body["stripeToken"] = token;
    body["user_id"] = this.global.user._id;
    body["subscription_id"] = this.selectedPlanID;
    body["stripe_id"] = this.selectPlanData.stripe_id;
    if (couponApplied && couponCodeData["coupon"])
      body["coupon"] = couponCodeData["coupon"];
    body["price"] = price;
    this.global.post("subscribeForOneTime", JSON.stringify(body), async (data) => {
      if (data.success) {
        this.global.showToast("", this.global.termsArray[data.message]);
        this.getSubsriptionStatus();
        this.getSubscriptionData();
        this.router.navigate(["user"], { replaceUrl: true });
      } else {
        this.global.showDangerToast("Error", this.global.termsArray[data.message]);
      }
    }, (err) => {
      this.global.showDangerToast("Error", err.message);
    }, true);
  }

  getToken() {
    this.message = "Loading...";
    (<any>window).Stripe.card.createToken(
      {
        number: this.cardNumber,
        exp_month: this.expiryMonth,
        exp_year: this.expiryYear,
        cvc: this.cvc,
      },
      (status: number, response: any) => {
        if (status === 200) {
          this.message = `Success! Card token ${response.card.id}.`;
        } else {
          this.message = response.error.message;
        }
      }
    );
  }

  purchaseSubscription(token, couponApplied = false, couponCodeData = {}) {
    let classInstance = this;
    let body = {};
    body["stripeToken"] = token;
    body["user_id"] = this.global.user._id;
    body["subscription_id"] = this.selectedPlanID;
    body["payment_type"] = "stripe";
    if (couponApplied && couponCodeData["coupon"])
      body["coupon"] = couponCodeData["coupon"];
    body["stripe_id"] = this.selectPlanData.stripe_id;
    body["amount"] = this.selectPlanData.price * this.selectPlanData.validity;
    body["isUnlimitedPlan"] = this.isUnlimitedPlan ? "1" : "0";
    body["subs_source"] = this.SUBSCRIBED_FROM_WEB;
    this.global.post("subscribed", JSON.stringify(body), async (data) => {
      if (data.success) {
        this.global.showToast("", this.global.termsArray[data.message]);
        this.getSubsriptionStatus();
        this.getSubscriptionData();
        this.router.navigate(["user"], { replaceUrl: true });
      } else {
        if (this.global.termsArray[data.message]) {
          this.global.showDangerToast("Error", this.global.termsArray[data.message]);
        } else {
          this.global.showDangerToast("Error", this.global.termsArray["lbl_something_went_wrong"]);
        }
      }
    }, (err) => {
      this.global.showDangerToast("Error", err.message);
    }, true);
  }

  createPaymentIntent(subscription, couponCodeData) {
    let subscription_id = '';
    let postData = {
      subscription_id: subscription._id,
      price: subscription.price,
      title: subscription.name,
      coupon: couponCodeData
    }
    let stripe_public_key = this.envservice.settings.payment_mode == 1 ? this.envservice.settings.public_key_live : this.envservice.settings.public_key_test;
    let stripe = (<any>window).Stripe(stripe_public_key);
    if (this.global.user && this.global.user.country && this.global.user.country.code) {
      this.global.post("createPaymentIntent", postData, (data: any) => {
        if (data.success) {
          stripe.confirmSofortPayment(data.data.client_secret, {
            payment_method: {
              sofort: {
                // country: 'DE'
                country: this.global.user.country.code
              }
            },
            // Return URL where the customer should be redirected after the authorization.
            return_url: window.location.href,
          })
            .then(function (result) {
              if (result.error) {
                console.log(result.error, "stripe Error");
              }
            });
        } else {
          this.global.showDangerToast("Error", this.global.termsArray[data.message]);
        }
      }, (err) => {
        console.log("*****", err);
        this.global.showDangerToast("Error", err.message);
      }, true);
    } else {
      this.global.showDangerToast("Error", this.global.termsArray['err_choose_country_from_profile']);
    }

  }



  updatePlan(couponApplied = false, couponCodeData = {}, template: TemplateRef<any>, data = "big-model-dialog2") {
    this.couponCode = "";
    this.is_coupon_Applied = couponApplied;
    if (this.selectedPlanID == null || this.selectedPlanID == "") {
      alert(this.global.termsArray["msg_subs_select_plan"]);
      return;
    } else if (this.planAlreadyPurchased) {
      alert(this.global.termsArray["lbl_already_selected"]);
      return;
    }
    this.modalRef.hide();
      const config = {
        animated: true,
        keyboard: true,
        backdrop: true,
        ignoreBackdropClick: true,
        class: data,
      };
      this.modalRef = this.modalService.show(template, config);
  }

 */

  /** pop up to cancel subscription */

  initMypopup(template: TemplateRef<any>, data: any) {
    const config = {
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: false,
      class: data,
    };
    this.modalRef = this.modalService.show(template, config);
  }

  cancelSubscription(id: string) {
    if (!id) return;
    let body = {};
    body["subscription_id"] = id;
    this.global.post("cancelSubs", JSON.stringify(body), (data) => {
      if (data.success) {
        this.getSubscriptionData();
        this.show = false;
        this.global.showToast("", this.global.termsArray[data.message]);
      } else {
        this.global.showDangerToast("Error", this.global.termsArray[data.message]);
      }
    }, (err) => {
      this.global.showDangerToast("Error", err.message);
    }, true);
  }

  /** Select any subscription plan */
  choosePlan(subscription) {
    if (!this.is_unlimited_subscribed) {
      this.subscribedId = subscription.subscribed_id;
      if ($('#exampleCheck_' + subscription._id).prop('checked') && this.acceptTerms == 'exampleCheck_' + subscription._id) {

        this.show = false;
      } else if (this.acceptTerms != "" && this.acceptTerms != 'exampleCheck_' + subscription._id) {

        $('#' + this.acceptTerms).prop("checked", false)
        this.show = 'exampleCheck_' + subscription._id;
        this.acceptTerms = false;
        return;
      } else {
        this.acceptTerms = false;
        this.show = "exampleCheck_" + subscription._id;
        return;
      }
      this.subscribedId = null;
      this.selectPlanData = subscription;
      this.selectedPlanID = subscription._id;

      if (subscription.status == 1 && subscription.is_cancel != 1) {

        this.planAlreadyPurchased = true;
        this.subscribedId = subscription.subscribed_id;
      } else {
        this.planAlreadyPurchased = false;
      }
      (subscription.name == "Unbegrenzt") ? this.isUnlimitedPlan = true : this.isUnlimitedPlan = false;
    }
  }

  /** After Selecting any subscription plan  
   * select payment type
  */

  subscribeMyplan(){
    if(this.paymentOptionsForm.valid){
      this.paymentMethod = this.paymentOptionsForm.value.payment_method;
      this.modalRef.hide();
      if(this.paymentMethod=='gift_card'){
        const config = {
          animated: true,
          keyboard: true,
          backdrop: true,
          ignoreBackdropClick: false,
          class: 'big-model-dialog2 discountPOPUP',
        };
        // this.modalRef = this.modalService.show('giftcode', config);
        this.openModal2(this.giftCodeModelRef,'big-model-dialog2',true);
        this.giftCardPaymentOptionsForm = this.fb.group({
          gift_card_payment_method: ['',Validators.required]
        });
      }
      else{
        this.openModal2(this.couponcodeModelRef,'big-model-dialog2',true);
      }
      this.resetForm(this.paymentOptionsForm);

      /** Doing this to intialize paypal container */
      if(this.paymentMethod == 'paypal'){
        this.requestToPaypal(this.subData,this.couponCodeData);
      }  
    }else{
      alert("Please select a payment option");
    }
  }

  resetForm(form:any){
    form.reset();
  }
  /** Apply coupon code */
  applyCouponCode(coupon, template: TemplateRef<any>) {
    if (!coupon) {
      return;
    }
    let body = {};
    body["code"] = coupon;
    body["subscription_id"] = this.selectedPlanID;
    this.global.post("apply-coupon", JSON.stringify(body), (data) => {
      if (data.success) {
        this.couponCodeId = data.data;
        this.couponCode = "";
        this.modalRef.hide();
        const config = {
          animated: true,
          keyboard: true,
          backdrop: true,
          ignoreBackdropClick: false,
          class: 'big-model-dialog2 discountPOPUP',
        };
        this.modalRef = this.modalService.show(template, config);
        this.couponCodeData = data.data;
        console.log("couponCodeData",this.couponCodeData)
        this.global.showToast("", this.global.termsArray[data.message]);
        if(this.paymentMethod == "paypal"){
          setTimeout(() => {
            this.requestToPaypal(this.subData, this.couponCodeData, "paypal_button_container2");
          }, 100);
        }
      } else {
        this.couponCode = "";
        this.global.showDangerToast("Error", this.global.termsArray[data.message]);
      }
    },
      (err) => {
        this.couponCode = "";
        this.modalRef.hide();
        this.global.showDangerToast("Error", err.message);
      },
      false
    );
  }



  openModal(template: TemplateRef<any>, data = "big-model-dialog", subscription = "", unlimited = false) {
    /** Added some delay for GTM events */
    setTimeout(() => {
      dataLayer.push({ 'event': 'start-buying-process' });
      console.log("Event fired (start-buying-process)");
    }, 3000);
    this.is_unlimited = unlimited;
    const config = {
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: true,
      class: data,
    };
    this.show = false;
    this.choosePlan(subscription);
    this.subData = subscription;
    this.modalRef = this.modalService.show(template, config);
  }

  openModal2(template: TemplateRef<any>, data = "big-model-dialog", ignoreBackdropClick=false) {
    const config = {
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick:ignoreBackdropClick,
      class: data,
    };
    this.show = false;
    this.modalRef = this.modalService.show(template, config);
  }

  goBack() {
    this.router.navigate(["setting"], { replaceUrl: true });
  }

  transform(date: any, args?: any): any {
    return moment(moment(date)).format("DD.MM.YYYY");
  }

  doSomething(e) {
    if (e.target.checked) {
      this.acceptTerms = this.show;
      this.show = false;
    } else {
      this.acceptTerms = false;
    }
  }


  purchaseInitially() {
    this.selectedPlanID = this.product_id;
    $('#exampleCheck_' + this.product_id).prop('checked', true);
    this.subscriptionList.map((data, i) => {
      if (data._id == this.product_id) {
        this.acceptTerms = "exampleCheck_" + this.product_id;
        this.show = false;
        this.subscribedId = null;
        this.selectPlanData = data;
        this.selectedPlanID = data._id;
        if (data.status == 1 && data.is_cancel != 1) {
          this.planAlreadyPurchased = true;
          this.subscribedId = data.subscribed_id;
        } else {
          this.planAlreadyPurchased = false;
        }

        (data.name == "Unbegrenzt") ? this.isUnlimitedPlan = true : this.isUnlimitedPlan = false;
        setTimeout(() => { $("." + this.product_id).trigger("click"); }, 1000)
      }
    })
  }




  /**STRIPE CHECKOUT SESSION >>>> */
  subscribeToStripe() {
    /**Check Old subscriptions>>>> */
    this.global.post("check-old-subs", {}, (response: any) => {
      if (response.success == true) {
        /**STRIPE CHECKOUT SESSION >>>> */
        const subs = this.subData;
        const subsId = subs && subs._id ? subs._id : subs;
        let stripeRequest = { basesubs_id: subsId, payment_method: this.paymentMethod };
        if(this.couponCodeData && this.couponCodeData.code){
          stripeRequest['coupon_code'] = this.couponCodeData.code;
        }
        this.global.post("subscribe-stripe", stripeRequest, (resp: any) => {
          if (resp.success == true) {
            let url = resp.data.payment_link;
            window.location.href = url;
          } else {
            this.global.showDangerToast("Error", resp.message);
          }
        }, (err: any, httpErr: any) => {
          console.log(httpErr);
          this.global.showDangerToast("Error", httpErr.error.message);
        }, true);
      }
      else {
        this.global.showDangerToast("Error", response.message);
      }
    }, (err1: any, httpErr1: any) => {
      console.log(httpErr1);
      this.global.showDangerToast("Error", httpErr1.error.message);
    }, true);
  }
  /**STRIPE CHECKOUT SESSION <<<< */

  
  /**PAYPAL INTEGRATION >>>> */
  requestToPaypal(subcription: any, couponInfo: any, tagId:string = "paypal_button_container",giftCodeInfo: any=null) {
    let amount = Number(subcription.price.toFixed(2));
    if (couponInfo) {
      amount = couponInfo.total - couponInfo.discount_amount;
      amount = amount >= 0 ? Number(amount.toFixed(2)) : 0;
    }
    if(giftCodeInfo){
      amount = giftCodeInfo.total - giftCodeInfo.discount_amount;
      amount = amount >= 0 ? Number(amount.toFixed(2)) : 0;
    }

    this.initPaypal(amount, subcription, couponInfo, tagId,this.global.user._id,giftCodeInfo);
  }

  initPaypal(amount: number, subcription: any, couponInfo: any, tagId:string,user_id:any,giftCodeInfo: any=null) {
    const clsInstance = this;
    // customid = user_id,subscription_id,coupon_id,is_gift_card_purchase,giftcode
    let custom_id = user_id+','+subcription._id;
    custom_id = couponInfo && couponInfo._id ? custom_id+','+couponInfo._id : custom_id+',null';
    if(giftCodeInfo){
      custom_id=custom_id+',false,'+giftCodeInfo.giftcode
    }
    this.global.post("check-old-subs", {}, (response: any) => {
      if (response.success == true) {
        document.querySelector("#"+tagId).innerHTML = "";
        paypal.Buttons({
          style: {
            layout:'horizontal',
            color:'white',
            height:55,
          },
          createOrder: function (data, actions) {
            return actions.order.create({
              purchase_units: [{
                amount: {
                  value: amount /** Set the payment amount */
                },
                custom_id:custom_id
              }]
            });
          },
          onApprove: async function (data, actions) {
            let userSub =await clsInstance.IntialisePaypalPayment(subcription, couponInfo,giftCodeInfo)
             return actions.order.capture().then(function (details) {
              // clsInstance.newSubscribeToPaypal(subcription, couponInfo, details);
              clsInstance.modalRef.hide();
              clsInstance.couponCodeData = null;
              clsInstance.router.navigate(['access-confirmation']);
            });
          },
          onCancel: function (data, actions) {
            clsInstance.modalRef.hide();
            clsInstance.couponCodeData = null;
          }
        }).render("#"+tagId);
      } else {
        this.global.showDangerToast("Error", response.message);
      }
    }, (err: any, httpErr: any) => {
      console.log(httpErr);
      this.global.showDangerToast("Error", httpErr.error.message);
    }, true);
  }
  closePaypalPop(event: any) {
    if (event.target.classList.contains("close_on_overlay")) {
      event.target.classList.remove('active');
    }
  }

  /**PAYPAL INTEGRATION <<<< */

  isActiveMethod(method:string){
    return this.activePaymentOptions.indexOf(method) >= 0;
  }
  showFakeFireButtons(){
    return this.allowedEmailsForFakeButton.indexOf(this.global.user.email) >= 0;
  }
  fireEvent(eventName:string){
    dataLayer.push({'event':eventName});
    this.global.showToast("", "Fired "+eventName);
  }
  getAffiliateInfo(){
    return new Promise((resolve, reject)=>{
      this.global.post("get-affiliate-info", JSON.stringify({}), (response:any) => {
        if (response.success && response.data) {
          let data:any = response.data;
          PostAffTracker.setAccountId('default1');
          let sale = PostAffTracker.createSale();
          sale.setTotalCost(data.baseSubsData.price);
          sale.setOrderID(data.subscription_name);
          sale.setProductID(data.baseSubsData._id);
          PostAffTracker.register();
          console.log("Affiliate sale marked");
          resolve(response.data);
        } else {
         reject();
        }
      }, (err) => {
        reject();
        this.global.showDangerToast("Error", err.message);
      }, true);
    })
  }

  IntialisePaypalPayment(subs: any, coupon: any,giftCodeInfo: any=null) {
    const subsId = subs && subs._id ? subs._id : subs;
    let requestBody = {
      basesubs_id: subsId,
      coupon: coupon,
      giftCode:giftCodeInfo
    };
    this.global.post("initPaypalPayment", requestBody, async (response: any) => {
      if (response.success == true) {
        // this.global.showToast("", this.global.termsArray[response.message]);
      } else {
        this.global.showDangerToast("Error", response.message);
      }
    }, (err: any, httpErr: any) => {
      console.log(httpErr);
      this.global.showDangerToast("Error", httpErr.error.message);
    }, true);
  }


  newSubscribeToPaypal(subs: any, coupon: any, details:any) {
    console.log(details)
    let classInstance = this;
    const subsId = subs && subs._id ? subs._id : subs;
    let requestBody = {
      basesubs_id: subsId,
      coupon: coupon,
      paypal_subscription_id: details.id,
      paypalData :details
    };

    const clsInstance = this;
    this.global.post("newsubscribeByPaypal", requestBody, async (response: any) => {
      clsInstance.modalRef.hide();
      if (response.success == true) {
        this.global.showToast("", this.global.termsArray[response.message]);
      } else {
        this.global.showDangerToast("Error", response.message);
      }
    }, (err: any, httpErr: any) => {
      console.log(httpErr);
      this.global.showDangerToast("Error", httpErr.error.message);
    }, true);
  }

  // template: TemplateRef<any>

  /** Step 1=> Apply gift card code */
  applyGiftCardCode(giftCardCode, template: TemplateRef<any>) {
    if (!giftCardCode) {
      return;
    }
    let body = {};
    body["code"] = giftCardCode;
    body["subscription_id"] = this.selectedPlanID;
    this.global.post("apply-gift-code", JSON.stringify(body), (data) => {
      if (data.success) {
        this.giftCodeId = data.data;
        this.giftCode = "";
        this.modalRef.hide();
        const config = {
          animated: true,
          keyboard: true,
          backdrop: true,
          ignoreBackdropClick: false,
          class: 'big-model-dialog2 discountPOPUP',
        };
        this.giftCodeData = data.data;
        this.global.showToast("", this.global.termsArray[data.message]);
        this.openModal2(this.giftCodeDiscountResultModelRef,'big-model-dialog2',true)
      } else {
        this.giftCode = "";
        this.global.showDangerToast("Error", this.global.termsArray[data.message]);
      }
    },
      (err) => {
        this.giftCode = "";
        this.modalRef.hide();
        this.global.showDangerToast("Error", err.message);
      },
      true
    );
  }

  /** Step 2=> after applying gift code manage result 
   * if amount is 0 then direct subscribe
   * else open payment method modal
  */

  manageGiftCodePaymentResult(){
    if(!this.giftCodeData || Object.keys(this.giftCodeData).length==0){
        return;
    }
    else if(this.giftCodeData.discount_less==0 ){
      this.applyGiftCode(this.giftCodeData.giftcode,this.giftCodeData)
    }
    else{
      this.modalRef.hide();
      this.openModal2(this.chooseGiftCardSubscriptionPaymentTypeModelRef, 'big-model-dialog2 modal-dialog-centered',true)
    }
  }

  /** step 3.a if after applying gift code amount is 0 */

  applyGiftCode(gift,giftCodeData) {
    if (!gift) {
      this.global.showDangerToast("Error",this.global.termsArray['lbl_gift_code'] );
      return;
    }
    let body = {};
    body["code"] = gift;
    body["subscription_id"] = this.selectedPlanID;
    body["giftCodeData"]=giftCodeData
    this.global.post("subscribeByGiftCard", JSON.stringify(body), (data) => {
      if (data.success) {
        this.modalRef.hide();
        this.getSubsriptionStatus();
        this.getSubscriptionData();
        this.global.showToast("", this.global.termsArray[data.message]);
      } else {
        this.giftCode = "";
        this.modalRef.hide();
        this.global.showDangerToast("Error",this.global.termsArray[data.message] );
      }
    },
      (err) => {
        this.giftCode = "";
        this.modalRef.hide();
        this.global.showDangerToast("Error", err.message);
      },
      true
    );
  }

  /** step 3.b if after applying gift code amount is greater than 0 */
  
  subscribeByGiftCards() {
    if(this.giftCardPaymentOptionsForm.valid){
      this.giftCardpaymentMethod = this.giftCardPaymentOptionsForm.value.gift_card_payment_method;
        if(this.giftCardpaymentMethod == 'paypal'){
          setTimeout(() => {
            this.requestToPaypal(this.subData,null,'paypal_button_container3',this.giftCodeData);
          }, 100);
        }  
        else{
          this.subscribeToStripeByGiftCard()
        }
    }
  }


  /*** Payment method is gift code  */
  subscribeToStripeByGiftCard() {
    const subs = this.subData;
    const subsId = subs && subs._id ? subs._id : subs;
    let stripeRequest = { basesubs_id: subsId, payment_method: this.giftCardpaymentMethod };
    if(this.giftCodeData && this.giftCodeData.giftcode){
      stripeRequest['gift_code'] = this.giftCodeData.giftcode;
    }
    this.global.post("subscribe-stripe-by-gift-cards", stripeRequest, (response: any) => {
      if (response.success == true) {
        let url = response.data.payment_link;
        window.location.href = url;
      } else {
        this.global.showDangerToast("Error", response.message);
      }
    }, (err: any, httpErr: any) => {
      console.log(httpErr);
      this.global.showDangerToast("Error", httpErr.error.message);
    }, true);
  }

  doAnotherthing() {
    setTimeout(() => {
      this.requestToPaypal(this.subData,null,'paypal_button_container3',this.giftCodeData);
    }, 100);
  }


  purchaseSubscriptionByCouponCode(couponCodeData) {
    if (!couponCodeData.code) {
      this.global.showDangerToast("Error",this.global.termsArray['lbl_coupon_code'] );
      return;
    }
    let body = {};
    body["code"] = couponCodeData.code;
    body["subscription_id"] = this.selectedPlanID;
    body["couponCodeData"]=couponCodeData
    this.global.post("subscribeByCouponCode", JSON.stringify(body), (data) => {
      if (data.success) {
        this.modalRef.hide();
        this.getSubsriptionStatus();
        this.getSubscriptionData();
        this.global.showToast("", this.global.termsArray[data.message]);
      } else {
        this.couponCode = "";
        this.couponCodeData=null
        this.modalRef.hide();
        this.global.showDangerToast("Error",this.global.termsArray[data.message] );
      }
    },
      (err) => {
        this.couponCode = "";
        this.couponCodeData=null
        this.modalRef.hide();
        this.global.showDangerToast("Error", err.message);
      },
      true
    );
  }

  checkDeprecatedSubscriptions(subs_id){
    const blocked_subscriptions = this.global && this.global.blocked_subscriptions;
    const subs = blocked_subscriptions.split(',');
    if(subs.indexOf(subs_id)!==-1){
      return true;
    }
    return false;
  }

}
// subscribe-stripe-by-gift-cards
// j#ikgl